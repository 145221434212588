<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

// import {
//   smilAnimationChart,
//   simpleLineChart,
//   polarBarChart,
//   areaLineChart,
//   lineSmoothingChart,
//   overlappingBarChart,
//   stackBarChart,
//   horizontalBarChart,
//   donutAnimateChart,
//   distributedSeries,
//   labelPlacementChart,
//   extremeConfiguration,
//   lineAreaChart
// } from "../../charts/data-chartist";

/**
 * Crypto Dashboard Component
 */
export default {
  page: {
    title: "Crypto Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { Layout, PageHeader },
  data() {
    return {
      data: {},
      topFive: [],
      simplePieChart: [],
      simpleBarChart: [],
      driverBarChart: [],
      trendingProducts: [],
      trendingDrivers: [],
      orders: 0,
      title: "",
      items: [
        {
          text: "Charts",
          href: "/",
        },
        {
          text: "Chartist Charts",
          active: true,
        },
      ],
    };
  },
  methods: {
    loadDashboard() {
      this.http.get("users/dashboard").then((res) => {
        if (res.status) {
          // res.data.top_vendors = JSON.parse(res.data.top_vendors)
          this.data = res.data;
          this.simplePieChart = res.charts.pie;
          this.simpleBarChart = res.charts.bar;
          this.driverBarChart = res.charts.drivers_barChart;
          this.trendingProducts = res.trends.products;
          this.trendingDrivers = res.trends.drivers;
          this.orders = res.orders?.length;
          console.log(35, this.simpleBarChart);
        }
      });
    },
  },
  async mounted() {
    await this.loadDashboard();
  },
};
</script>

<script setup>
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { useRouter } from "vue-router";
// import { useI18n } from "vue-i18n";
// import Swal from "sweetalert2";

// const i18n = useI18n();
// const requestAlert = (
//   text = "Are You Sure ?",
//   confirmButtonText = "OK",
//   cancelButtonText = "Cancel"
// ) => {
//   return new Promise((resolve, reject) => {
//     Swal.fire({
//       title: text,
//       showCancelButton: true,
//       confirmButtonColor: "#249542",
//       cancelButtonColor: "gray",
//       confirmButtonText,
//       cancelButtonText,
//     })
//       .then(async (result) => {
//         if (result.isConfirmed) {
//           return resolve(true);
//         }
//         return resolve(false);
//       })
//       .catch((error) => {
//         reject(false);
//         console.error("Error deleting store: ", error);
//       });
//   });
// };

// const router = useRouter();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_Y80JBts_l9QsLvgH_1L0I-9m9SDic6w",
  authDomain: "mulim-app.firebaseapp.com",
  projectId: "mulim-app",
  storageBucket: "mulim-app.appspot.com",
  messagingSenderId: "1098357395066",
  appId: "1:1098357395066:web:9b98260f55cd8dd7a3b5c3",
  measurementId: "G-64VQKNCV73",
};

// Initialize Firebase
initializeApp(firebaseConfig);
// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js", { scope: "/" })
  .then(
    async function (reg) {
      var serviceWorker;
      if (reg.installing) {
        serviceWorker = reg.installing;
        // console.log('Service worker installing');
      } else if (reg.waiting) {
        serviceWorker = reg.waiting;
        // console.log('Service worker installed & waiting');
      } else if (reg.active) {
        serviceWorker = reg.active;
        // console.log('Service worker active');
      }

      if (serviceWorker) {
        console.log("sw current state", serviceWorker.state);

        if (serviceWorker.state == "activated") {
          // subscribeForPushNotification();
          //If push subscription wasnt done yet have to do here
          console.log("sw already activated - Do watever needed here");
          // subscribeForPushNotification(reg);
        }
        serviceWorker.addEventListener("statechange", function (e) {
          console.log("sw statechange : ", e.target.state);
          if (e.target.state == "activated") {
            // use pushManger for subscribing here.
            console.log(
              "Just now activated. now we can subscribe for push notification"
            );
            subscribeForPushNotification(reg);
          }
        });
        // if (
        //   await requestAlert(
        //     i18n.t("popups.not_notify"),
        //     i18n.t("popups.go_to_notify"),
        //     i18n.t("popups.cancel")
        //   )
        // )
        //   return router.push("/notifications");
      } else {
        console.log("not registred");
      }
    },
    function (err) {
      console.error(
        "unsuccessful registration with ",
        "firebase-messaging-sw.js",
        err
      );
    }
  );

function subscribeForPushNotification() {
  getToken(messaging, {
    vapidKey:
      "BCQiLjHaoGLRHgwT7dWQ5Yr_EHVT75jziOhMzFdcOaepsf0IoLVy-Oxj6n3biOYjpm5wRX7S-hfFaudxKNMZeJA",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log({ currentToken });
        // subscribeTokenToTopic(currentToken, "dev");
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
}

// function subscribeTokenToTopic(token, topic) {
//   fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
//     method: "POST",
//     headers: new Headers({
//       Authorization:
//         "key=AAAA_7szzno:APA91bE_FzckcewZeDqb99O4bZwnkraoaeFe_bmfMXLVryxrIYZDj89pDtR-ah-TLh0tYBoHak2hJoHVUgCfzFs2Um6lhMOQbiXRcsIkwnxlKsyWv_7oJRwCXuTIu6rEiplMUURsh_qe",
//     }),
//   })
//     .then((response) => {
//       if (response.status < 200 || response.status >= 400) {
//         console.log(response.status, response);
//       }
//       console.log(response.status, response);
//       console.log("${topic}", response);
//     })
//     .catch((error) => {
//       console.error(error.result);
//     });
//   return true;
// }
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="justify-content-start nav-tabs-custom"
      >
        <!-- marketing channels -->
        <b-tab :title="$t('menu.menuitems.statics.text')" active>
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div
                class="swiper-slide card card-slide"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center gap-3">
                    <div class="rounded p-3" style="background-color: #fff8ed">
                      <i class="bx bx-user"></i>
                    </div>
                    <div class="text-right dashboard-show-data">
                      <h5 class="counter">
                        {{ $t("menu.menuitems.vendors.text") }}
                      </h5>
                      <h4 class="counter">{{ data?.vendors }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="swiper-slide card card-slide"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center gap-3">
                    <div class="rounded p-3" style="background-color: #fff8ed">
                      <i class="bx bx-user"></i>
                    </div>
                    <div class="text-right dashboard-show-data">
                      <h5 class="counter">
                        {{ $t("menu.menuitems.customers.text") }}
                      </h5>
                      <h4 class="counter">{{ data?.customers }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="swiper-slide card card-slide"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center gap-3">
                    <div class="rounded p-3" style="background-color: #fff8ed">
                      <i class="bx bx-user"></i>
                    </div>
                    <div class="text-right dashboard-show-data">
                      <h5 class="counter">
                        {{ $t("menu.menuitems.drivers.text") }}
                      </h5>
                      <h4 class="counter">{{ data?.drivers }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="swiper-slide card card-slide"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div class="card-body">
                  <div class="d-flex align-items-center gap-3">
                    <div class="rounded p-3" style="background-color: #fff8ed">
                      <i class="bx bx-list-check"></i>
                    </div>
                    <div class="text-right dashboard-show-data">
                      <h5 class="counter">
                        {{ $t("menu.menuitems.orders.text") }}
                      </h5>
                      <h4 class="counter">{{ orders }}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    {{ $t("reports.trending_products") }}
                  </h4>
                  <table
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="table mt-1"
                  >
                    <thead>
                      <tr
                        class="text-light text-center"
                        style="
                          background-color: #2a3042 !important ;
                          text-align: start;
                        "
                      >
                        <th scope="col">{{ $t("products.id") }}</th>
                        <th scope="col">{{ $t("products.name") }}</th>
                      </tr>
                    </thead>
                    <!-- Simple pie chart -->
                    <tbody style="text-align: center">
                      <tr v-for="product in trendingProducts" :key="product">
                        <td>{{ product.apper_times }}</td>
                        <td>{{ product.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    {{ $t("reports.trending_drivers") }}
                  </h4>
                  <table
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="table mt-1"
                  >
                    <thead>
                      <tr
                        class="text-light text-center"
                        style="
                          background-color: #2a3042 !important ;
                          text-align: start;
                        "
                      >
                        <th scope="col">{{ $t("reports.id") }}</th>
                        <th scope="col">{{ $t("reports.name") }}</th>
                      </tr>
                    </thead>
                    <!-- Simple pie chart -->
                    <tbody style="text-align: center">
                      <tr v-for="driver in trendingDrivers" :key="driver">
                        <td>{{ driver.apper_times }}</td>
                        <td>{{ driver.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab :title="$t('menu.menuitems.chart.text')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">{{ $t("reports.services") }}</h4>
                  <!-- Simple pie chart -->
                  <chartist
                    ratio="ct-chart"
                    :data="simplePieChart.data"
                    :options="simplePieChart.options"
                    type="Pie"
                  >
                  </chartist>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">{{ $t("reports.services") }}</h4>
                  <!-- Simple pie chart -->
                  <chartist
                    ratio="ct-chart"
                    type="Bar"
                    :data="simpleBarChart?.data"
                    :options="simpleBarChart.options"
                  >
                  </chartist>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title mb-4">
                    {{ $t("reports.trending_drivers") }}
                  </h4>
                  <!-- Simple pie chart -->
                  <chartist
                    ratio="ct-chart"
                    type="Bar"
                    :data="driverBarChart?.data"
                    :options="driverBarChart.options"
                  >
                  </chartist>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>
